.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.editor__port-row-io--margin {
  background: rgb(18, 184, 134);
}

.editor__port-row-io {
  border-bottom: solid 1px rgba(18, 184, 134);
}

.editor__port-row-io--error {
  border-bottom: solid 1px rgb(255, 107, 107);
}

@keyframes fade-in-out {
  0% {
    fill-opacity: 1;
  }
  50% {
    fill-opacity: 0;
  }
  100% {
    fill-opacity: 1;
  }
}

.running {
  animation: fade-in-out 2s ease-in-out infinite;
}

@keyframes ping {
  75%,
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}

.ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.single-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1);
}

.node-shape_foreground {
  stroke-width: 0;
  transition: stroke-width 0.2s ease-in-out;
}

.node-shape_hover {
  stroke-width: 15;
}

.node-form_foreground {
  box-shadow: 0 0 0 0 rgba(173, 181, 189, 0.2);
  transition: box-shadow 0.2s ease-in-out;
}

.node-form_hover {
  box-shadow: 0 0 0 15px rgba(173, 181, 189, 0.2);
}

@keyframes success {
  33% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.success-animation {
  animation: success 1s cubic-bezier(0, 0, 0.2, 1);
}

.markdown h1:first-child,
.markdown h2:first-child,
.markdown h3:first-child {
  margin-top: 0;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin-top: 1em;
  font-weight: 500;
  margin-bottom: 0.75em;
}

/* Header scaled values inspired from: https://www.smashingmagazine.com/2011/03/technical-web-typography-guidelines-and-techniques/#setting-a-scale */

.markdown h1 {
  font-size: 1.5em;
  line-height: 1em;
}

.markdown h2 {
  font-size: 1.375em;
  line-height: 1.0909em;
}

.markdown h3 {
  font-size: 1.25em;
  line-height: 1.2em;
}

.markdown h4 {
  font-size: 1.125em;
  line-height: 1.333em;
}

.markdown h5 {
  font-size: 1em;
  line-height: 1.5em;
}

.markdown h6 {
  font-size: 1em;
  line-height: 1.5em;
}

.markdown ul,
.markdown ol {
  padding-left: 1.85em;
  margin-bottom: 0.75em;
}

.markdown ul > ul,
.markdown ol > ol {
  margin-top: 0.25em;
}

.markdown li {
  margin-top: 0.25em;
}

.markdown p,
.markdown a {
  margin-bottom: 0.75em;
}

.markdown img {
  width: 90%;
  margin: auto;
}

.markdown code {
  margin-bottom: 0.75em;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ran into a specificity battle with atlas, might need to be tweaked later */
#atlas-chat div.positioning.bottom-left {
  /* 18px (atlas margin) + 38px (sidebar width) */
  left: 53px;
}
