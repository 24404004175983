.chakra-ui-dark .react-flow__controls button {
  background: #444;
  border-bottom-color: #666;
}

.chakra-ui-dark .react-flow__controls button path {
  fill: #eee;
}

.react-flow__minimap {
  background: white;
}

.chakra-ui-dark .react-flow__minimap {
  background: #222;
}


.patternsNode > * {
  pointer-events: all;
}
