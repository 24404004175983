.monaco-diff-editor .editor.original {
  right: 0 !important;
  left: unset !important;
}
.monaco-diff-editor .editor.modified {
  left: 0 !important;
  width: unset !important;
}
.monaco-diff-editor .diffOverview .diffOverviewRuler.original {
  left: 15px !important;
}
.monaco-diff-editor .diffOverview .diffOverviewRuler.modified {
  right: 15px !important;
}

/* .original-in-monaco-diff-editor .overflow-guard .margin {
  background-color: #deddda !important;
}

.original-in-monaco-diff-editor
  .overflow-guard
  .monaco-scrollable-element
  .monaco-editor-background {
  background-color: #deddda !important;
} */
