.tableViewTdTop {
  color: #5f6b7c;
  bgcolor: #f6f7f9;
  text-align: right;

  border-bottom: 0px;
  box-shadow: inset 0 -1px 0 rgb(17 20 24 / 15%), 1px 0 0 rgb(17 20 24 / 15%);
  height: 20px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-size: 12px;
  user-select: none;
}

.tableViewTd {
  color: #1c2127;
  bgcolor: undefined;
  border-bottom: 0px;
  box-shadow: inset 0 -1px 0 rgb(17 20 24 / 15%), 1px 0 0 rgb(17 20 24 / 15%);
  height: 20px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-size: 12px;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: #2d72d2;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
